import './scaffold.css'
import './index.scss'

import FatalErrorPage from 'src/pages/FatalErrorPage'
import Routes from 'src/Routes'
import keycloak from './keycloak'
import { ReactKeycloakProvider } from '@react-keycloak/web'
import { FatalErrorBoundary, RedwoodProvider } from '@redwoodjs/web'
import { RedwoodApolloProvider } from '@redwoodjs/web/apollo'
import React from 'react'

const eventLogger = (event, error) => {
  console.log('onKeycloakEvent', event, error)
}

const tokenLogger = (tokens) => {
  console.log('onKeycloakTokens', tokens)
}

const App = () => {
  return (
    <FatalErrorBoundary page={FatalErrorPage}>
      <RedwoodProvider>
        <ReactKeycloakProvider
          authClient={keycloak}
          onEvent={eventLogger}
          onTokens={tokenLogger}
        >
          <RedwoodApolloProvider>
            <Routes />
          </RedwoodApolloProvider>
        </ReactKeycloakProvider>
      </RedwoodProvider>
    </FatalErrorBoundary>
  )
}

export default App
