// In this file, all Page components from 'src/pages` are auto-imported. Nested
// directories are supported, and should be uppercase. Each subdirectory will be
// prepended onto the component name.
//
// Examples:
//
// 'src/pages/HomePage/HomePage.js'         -> HomePage
// 'src/pages/Admin/BooksPage/BooksPage.js' -> AdminBooksPage

import { Route, Router, Redirect } from '@redwoodjs/router'

import { useKeycloak } from '@react-keycloak/web'

import React from 'react'

export function PrivateRoute({ unauthenticated, role, ...rest }) {
  const { keycloak } = useKeycloak()
  let checker = (arr, target) => target.every((v) => arr.includes(v))
  const hasRole = checker(keycloak?.realmAccess?.roles || [], role)
  return keycloak?.authenticated && hasRole ? <>{rest.children}</> : <Redirect to={unauthenticated} />
}

const Routes = () => {
  return (
    <Router trailingSlashes={'preserve'}>
      <Route path="/disc-jobs/new" page={NewDiscJobPage} name="newDiscJob" />
      <Route path="/disc-jobs/{id}/edit" page={EditDiscJobPage} name="editDiscJob" />
      <Route path="/disc-jobs/{id}" page={DiscJobPage} name="discJob" />
      <Route path="/disc-jobs" page={DiscJobsPage} name="discJobs" />
      <Route path="/disc-referals/new" page={NewDiscReferalPage} name="newDiscReferal" />
      <Route path="/disc-referals/{id}/edit" page={EditDiscReferalPage} name="editDiscReferal" />
      <Route path="/disc-referals/{id}" page={DiscReferalPage} name="discReferal" />
      <Route path="/disc-referals" page={DiscReferalsPage} name="discReferals" />
      <Route path="/" redirect="/admin-gate" />
      <Route path="/admin-gate" page={AdminGatePage} name="adminGate" />
      <PrivateRoute unauthenticated="/admin-gate" role={['admin']}>
        <Route path="/admin/disc/disc-send-mail/{id}" page={DiscSendMailPage} name="discSendMail" />
        <Route path="/admin/disc/disc-mail-detail/{id}" page={DiscMailDetailPage} name="discMailDetail" />
        <Route path="/disc-answers/new" page={NewDiscAnswerPage} name="newDiscAnswer" />
        <Route path="/disc-answers/{id:Int}/edit" page={EditDiscAnswerPage} name="editDiscAnswer" />
        <Route path="/disc-answers/{id:Int}" page={DiscAnswerPage} name="discAnswer" />
        <Route path="/disc-answers" page={DiscAnswersPage} name="discAnswers" />
        <Route path="/disc-forms/new" page={NewDiscFormPage} name="newDiscForm" />
        <Route path="/disc-forms/{id}/edit" page={EditDiscFormPage} name="editDiscForm" />
        <Route path="/disc-forms/{id}" page={DiscFormPage} name="discForm" />
        <Route path="/disc-forms" page={DiscFormsPage} name="discForms" />
        <Route path="/disc-questions/new" page={NewDiscQuestionPage} name="newDiscQuestion" />
        <Route path="/disc-questions/{id:Int}/edit" page={EditDiscQuestionPage} name="editDiscQuestion" />
        <Route path="/disc-questions/{id:Int}" page={DiscQuestionPage} name="discQuestion" />
        <Route path="/disc-questions" page={DiscQuestionsPage} name="discQuestions" />
        <Route path="/disc-users/new" page={NewDiscUserPage} name="newDiscUser" />
        <Route path="/disc-users/{id}/edit" page={EditDiscUserPage} name="editDiscUser" />
        <Route path="/disc-users/{id}" page={DiscUserPage} name="discUser" />
        <Route path="/disc-users" page={DiscUsersPage} name="discUsers" />
        <Route path="/admin/drun/disc-personal-detail" page={DiscPersonalDetailPage} name="discPersonalDetail" />
        <Route path="/admin/disc/dashboard" page={DiscFormsPage} name="discAdmin" />
        <Route path="/admin/disc/disc-chart/{id}" page={DiscChartPage} name="discChart" />
        <Route path="/accounts/new" page={NewAccountPage} name="newAccount" />
        <Route path="/accounts/{id}/edit" page={EditAccountPage} name="editAccount" />
        <Route path="/accounts/{id}" page={AccountPage} name="account" />
        <Route path="/accounts" page={AccountsPage} name="accounts" />
      </PrivateRoute>

      <Route notfound page={NotFoundPage} />
    </Router>
  )
}

export default Routes
