import Keycloak from 'keycloak-js'

// Setup Keycloak instance as needed
// Pass initialization options as required or leave blank to load from 'keycloak.json'
const keycloak = Keycloak({
  url: 'https://id.dili.group/auth',
  realm: 'DILI',
  clientId: 'admin-client',
})

export default keycloak
